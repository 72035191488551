






























import {
  defineComponent, useContext, useFetch, ref, computed,
} from '@nuxtjs/composition-api';
import { SfSection } from '@storefront-ui/vue';
import Carousel from '~/components/Home/Carousel.vue';
import Banner from '~/components/Home/Banner.vue';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  components: {
    SfSection,
    Carousel,
    Banner,
  },
  setup() {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const { getCatLink } = useUiHelpers();
    const { result, search }: any = useCategorySearch();
    const items = ref([]);
    const responsiveItems = computed(() => isDesktop ? items.value : items.value.slice(1));
    const settings = {
      type: 'slider',
      autoplay: 5000,
      perView: 1,
      breakpoints: {
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    };

    const formatCollection = (category, isMain = false) => ({
      title: isMain ? 'Our collections' : category.name,
      description:
        category.short_description || 'We have collected for you collections that have their own unique style and functionality. The advantage of our collections is natural wood, which is used in production. Get to know each collection closer and choose what you like.',
      buttonText: isMain ? 'All collections' : 'Go to collection',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      link: getCatLink(category),
      image: category.image || '/home/collections.png',
      nuxtImgConfig: {
        loading: 'lazy',
        format: 'webp',
        width: isDesktop ? 1200 : 600,
        height: isDesktop ? 630 : 600,
      },
    });

    useFetch(async () => {
      await search({ filters: { ids: { eq: '212' } } });
      const main = formatCollection(result.value?.[0], true);
      const children = (result.value?.[0]?.children || []).map((child) => formatCollection(child));

      items.value = [main, ...children];
    });

    return {
      settings,
      items,
      responsiveItems,
    };
  },
});
